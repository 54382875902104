<template>
	<div ref="obtain" class="main">
		
		<!--		轮播图-->
		<div class="banner">
			<el-carousel id="banner">
				<el-carousel-item v-for="(item,k) in imglist" :key="k">
					<img :src="item.url" alt="轮播图" class="img">
				</el-carousel-item>
			</el-carousel>
		</div>
		
		
		<!--		轮播图下方区域-->
		<div class="briefintroductionbox">
			<div class="briefintroductionbox-main">
				<div class="briefintroductionbox-main_flex_box">
					<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/OA.png">
					<p style="width: 100%; text-align: center; color: #fd3f31; font-size: 18px; margin-top: 2%;">
						满足公司企业办公所需</p>
					<span>
						从仓储管理，移动办公管理、合同预算管理都可以用大总管数字化信息管理平台全方位实现
					</span>
				</div>
				<div class="briefintroductionbox-main_flex_box">
					<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/OA2.png">
					<p style="width: 100%; text-align: center; color: #fd3f31;font-size: 18px; margin-top: 2%;">适合多种行业
					</p>
					<span>
<!--						大总管数字化信息管理平台真正做到了集企业办公管理、店面零售管理、美业健身管理、设备智能报修管理与一体的数字化信息管理平台-->
						大总管数字化信处管理平台真正做到了集合多种行业在一个平台的办公的管理，如工程建筑行业、装修装饰行业、律所办公行业、商会协会行业与一体的信息化管理平台
					</span>
				</div>
				<div class="briefintroductionbox-main_flex_box">
					<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/OA3.png">
					<p style="width: 100%; text-align: center; color: #fd3f31;font-size: 18px; margin-top: 2%;">提升您的管理效益
					</p>
					<span>
						系统内部集成多种数据报表的分析，能深入的分析店面运营情况，全面提升您店面的营销的能力为您的经营打造数字化移动信息平台
					</span>
				</div>
			</div>
		</div>
		
		
		<!--		数字化管理平台，满足公司企业店面所需-->
		<div class="timershaftbix">
			<div class="timershaftbix_main">
				<h1>数字化管理平台，满足公司企业店面所需</h1>
				
				<div class="timershaftbix_main_flex_box">
					<div class="left">
						<div id="Leftone" class="timershaftbix_main_flex_box_box">
							<p style="font-size: 22px;">大总管仓储移动办公管理</p>
							<p style="color: #fd3f31; font-size: 30px;">让管理数字化</p>
							<span
								 style="display: inline-block;width: 70%; color: #837f7d; font-size: 15px;">支持多种行业的仓储管理，办公管理，无缝集成移动信息设备的接入，真正实现多行业跨平台，全新一代的Saas的数字化信息管理平台</span>
							<div style="width: 100%; display: flex;justify-content: flex-end;">
								<button style=" display: block; border: 1px solid #fd3f31; background-color: rgba(0,0,0,0); color: #fd3f31;width: 200px;height: 60px;margin-top: 3%;border-radius: 5px;"
										  type="button"
										  @click="$router.push('/productpresentation')">点击了解大总管信息平台
								</button>
							</div>
						</div>
						<div id="Lefttwo" class="timershaftbix_main_flex_box_box">
							<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/TimesouOA2.png"
								  style="height: 100%;float: right;">
						</div>
						<div id="leftthree" class="timershaftbix_main_flex_box_box" style="text-align: right;">
							<p style="font-size: 22px;">大总管律所办公系统</p>
							<p style="color: #fd3f31; font-size: 30px;">让您的律所管理更轻松</p>
							<span
								 style="display: inline-block;width: 70%; color: #837f7d; font-size: 15px;">可通过电脑端、手机端对您律所的客户登记、案件收录、财务票据、公函合同实现实时在线审批，提升您的办公效率。</span>
							<button
								 style="display: block; border: 1px solid #fd3f31; background-color: rgba(0,0,0,0); color: #fd3f31;width: 200px;height: 60px;margin-top: 3%;float: right;border-radius: 5px;"
								 type="button"
								 @click="$router.push('/LawFirm/LawFirm')">了解大总管律所办公系统
							</button>
						
						</div>
						<div id="leftfour" class="timershaftbix_main_flex_box_box">
							<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/timewx.png"
								  style="height: 100%; float: right;">
						</div>
					</div>
					<div class="center">
						<img class="timeimg"
							  src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/Timesouimg.png">
					</div>
					<div class="right">
						<div id="rightone" class="timershaftbix_main_flex_box_box">
							<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/TimesouOA.png"
								  style="height: 100%;">
						</div>
						<div id="righttwo" class="timershaftbix_main_flex_box_box">
							<p style="font-size: 22px;">大总管信息管理平台</p>
							<p style="color: #fd3f31; font-size: 30px;">让数据尽在手中</p>
							<span
								 style="display: inline-block;width: 70%; color: #837f7d; font-size: 15px;">一个系统就能管理您的公司，企业，多端同步，用手机即可实时查询您的报表、商品、合同、客户、会员尽在掌握中</span>
							<button style="display: block; border: 1px solid #fd3f31; background-color: rgba(0,0,0,0); color: #fd3f31;width: 200px;height: 60px;margin-top: 3%;border-radius: 5px;"
									  type="button"
									  @click="OAfun(1)">点击了解大总管云后台
							</button>
						
						</div>
						<div id="rightthree" class="timershaftbix_main_flex_box_box">
							<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/Timgshop.png"
								  style="height: 100%;">
						</div>
						<div id="rightfour" class="timershaftbix_main_flex_box_box">
							<p style="font-size: 22px;">大总管智能报修</p>
							<p style="color: #fd3f31; font-size: 30px;">打造数字信息化的报修管理系统</p>
							<span
								 style="display: inline-block;width: 70%; color: #837f7d; font-size: 15px;">助力完成售后服务闭环，打通用户、企业关联有效提升企业竟争力</span>
							<button style="display: block; border: 1px solid #fd3f31; background-color: rgba(0,0,0,0); color: #fd3f31;width: 200px;height: 60px;margin-top: 3%;border-radius: 5px;"
									  type="button"
									  @click="$router.push('/medicaltreatment')">了解大总管智能报修
							</button>
						</div>
					</div>
				</div>
				<div>
				
				</div>
			</div>
		</div>
		<div class="industrybox">
			<h1>多种行业，都可以帮您轻松完成数字化管理</h1>
			<div class="industrybox_flex">
				<div class="industrybox_flex_box">
					<icon-svg class='iconclas' iconClass='bangongxitong'></icon-svg>
					<button class="btns" type="button" @click="OAfun(3)">我是企业办公行业</button>
				</div>
				<div class="industrybox_flex_box">
					<icon-svg class='iconclas' iconClass='icon-test1'></icon-svg>
					<button class="btns" type="button" @click="$router.push('/LawFirm/LawFirm')">我是律所办公行业</button>
				</div>
				<div class="industrybox_flex_box">
					<icon-svg class='iconclas' iconClass='dianpu1'></icon-svg>
					<button class="btns" type="button" @click="$router.push('/ERP/introduce')">我是ERP管理系统</button>
				</div>
				<div class="industrybox_flex_box">
					<icon-svg class='iconclas' iconClass='shengchanzhizao'></icon-svg>
					<button class="btns" type="button" @click="$router.push('/medicaltreatment')">我是设备智能报修行业</button>
				</div>
			</div>
		</div>
		
		<div class="manualbox">
			<h1>引领数字化信息管理平台</h1>
			<span>大总管联合国际知名的咨询公司，联手打造行业管理的数字化管理平台</span>
			<div class="cardboxs">
				<div class="card_flex_box" @click="OAfun(3)">
					<div class="card_flex_box_header">
						智能办公
					</div>
					<img class="card_flex_box_img"
						  src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/AIoa.png">
				</div>
				<div class="card_flex_box" @click="$router.push('/LawFirm/LawFirm')">
					<div class="card_flex_box_header">
						律所办公
					</div>
					<img class="card_flex_box_img"
						  src="https://bxexample.oss-cn-shanghai.aliyuncs.com/website/lvsuo.png">
				</div>
				<div class="card_flex_box" @click="$router.push('/ERP/introduce')">
					<div class="card_flex_box_header">
						ERP系统
					</div>
					<img class="card_flex_box_img"
						  src="https://bxexample.oss-cn-shanghai.aliyuncs.com/website/erp.png">
				</div>
				<div class="card_flex_box" @click="$router.push('/Businesssuperretail')">
					<div class="card_flex_box_header">
						商会管理
					</div>
					<img class="card_flex_box_img"
						  src="https://bxexample.oss-cn-shanghai.aliyuncs.com/website/shanghui.png">
				</div>
				<div class="card_flex_box" @click="$router.push('/medicaltreatment')">
					<div class="card_flex_box_header">
						智能报修
					</div>
					<img class="card_flex_box_img"
						  src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/BX.png">
				</div>
			</div>
		</div>
		<div class="bottombox">
			<div class="bottombox_main">
				<h1>选择大总管，帮你打造云管家</h1>
				<div class="bottom_box_flex_box">
					<div class="bottom_box_flex_box_box">
						<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/KPT.png">
						<p style="color: #fd3f31; font-size: 30px;">
							跨平台，跨设备
						</p>
						<p style="color: #322e2f;height: 30%;">
							您可以在Windows、ios、安卓使用大总管数字化信息平台，而无需额外购买硬件设备
						</p>
					</div>
					<div class="bottom_box_flex_box_box">
						<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/IT.png">
						<p style="color: #fd3f31; font-size: 30px;">
							零IT成本
						</p>
						<p style="color: #322e2f;height: 30%;">
							根据不同行业注册您将获得相关行业的属性系统，把维护、升级、云存储都交给我们，您无需任合的额外IT成本的支出
						</p>
					</div>
					<div class="bottom_box_flex_box_box">
						<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/sever.png">
						<p style="color: #fd3f31; font-size: 30px;">
							享受专业级服务
						</p>
						<p style="color: #322e2f;height: 30%;">
							大总管服务团队提供专业的服务顾问，为您提供远程协助，您可以选择电话、QQ、微信等任意方式联系我们
						</p>
					</div>
				</div>
				<button id="buttons" type="button" @click="zcfun">免费体验</button>
			</div>
		</div>
		<div class="phone_bri">
			<h2>大总管数字化管理系统</h2>
			<p>大总管为客户打造智能协同运营之道</p>
			<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/AIxt.png">
			<div class="text">
				<p><i class="icl">•</i>ERP管理系统</p>
				<p><i class="icl">•</i>律所办公系统</p>
				<p><i class="icl">•</i>OA数字化办公</p>
				<p><i class="icl">•</i>设备智能报修</p>
			</div>
			<div class="bton">
				<button type="button" @click="zcfun">免费试用</button>
			</div>
		</div>
		<div class="phone_industry">
			<p class="title">覆盖<span style="color: #fd3f31;">21大行业</span> 为您量身订制的云系统</p>
			<div id="collapse">
				<el-collapse v-model="activeName" accordion>
					<el-collapse-item name="1">
						<template slot="title">
							<div>
								<icon-svg class='iconclas' iconClass='cangku'></icon-svg>
								<span>
									ERP管理系统
								</span>
							</div>
						</template>
						<div class="detail_box">
							<p>大总管ERP管理系统</p>
							<span>
								集PC电脑，移动端为一体的智能企业管理系统
							</span>
						</div>
						<div class="btns">
							<button type="button" @click="$router.push('/ERP/introduce')">了解详情</button>
							<button type="button" @click="zcfun">免费试用</button>
						</div>
					</el-collapse-item>
					
					
					<el-collapse-item name="2">
						<template slot="title">
							<div>
								<icon-svg class='iconclas' iconClass='dianpu1'></icon-svg>
								<span>
									律所办公系统
								</span>
							</div>
						</template>
						<div class="detail_box">
							<p>大总管律所智能化管理系统</p>
							<span>
								专业、高效、严谨，为律所更好的完成案件管理工作
							</span>
						</div>
						<div class="btns">
							<button type="button" @click="$router.push('/LawFirm/LawFirm')">了解详情</button>
							<button type="button" @click="zcfun">免费试用</button>
						</div>
					</el-collapse-item>
					
					
					<el-collapse-item name="3">
						<template slot="title">
							<div>
								<icon-svg class='iconclas' iconClass='bangong0'></icon-svg>
								<span>
									OA办公
								</span>
							</div>
						</template>
						<div class="detail_box">
							<p>大总管OA办公系统</p>
							<span>
								让您能轻松完成移动办公，合同、客户等在线管理
							</span>
						</div>
						<div class="btns">
							<button type="button" @click="OAfun(1)">了解详情</button>
							<button type="button" @click="zcfun">免费试用</button>
						</div>
					</el-collapse-item>
					
					<el-collapse-item name="4">
						<template slot="title">
							<div>
								<icon-svg class='iconclas' iconClass='shengchanzhizao'></icon-svg>
								<span>
									智能报修系统
								</span>
							</div>
						</template>
						<div class="detail_box">
							<p>大总管智能报修系统</p>
							<span>
								4、大总管智能报修系统 微信报修 、智能派单 、自动提醒、备件管理、费用结算
							</span>
						</div>
						<div class="btns">
							<button type="button" @click="$router.push('/medicaltreatment')">了解详情</button>
							<button type="button" @click="zcfun">免费试用</button>
						</div>
					</el-collapse-item>
					<el-collapse-item name="5">
						<template slot="title">
							<div>
								<icon-svg class='iconclas' iconClass='19'></icon-svg>
								<span>
									更多行业
								</span>
							</div>
						</template>
						<div class="icon-font_box">
							<div class="icondetail_box" @click="$router.push('/OA')">
								<div class="icobox">
									<icon-svg class='iconclas' iconClass='bangongxitong'></icon-svg>
								</div>
								<p>企业</p>
							</div>
							<div class="icondetail_box" @click="$router.push('/LawFirm/LawFirm')">
								<div class="icobox">
									<icon-svg class='iconclas' iconClass='icon-test1'></icon-svg>
								</div>
								<p>律所</p>
							</div>
							<div class="icondetail_box" @click="$router.push('/ERP/introduce')">
								<div class="icobox">
									<icon-svg class='iconclas' iconClass='dianpu1'></icon-svg>
								</div>
								<p>ERP</p>
							</div>
							<div class="icondetail_box" @click="$router.push('/medicaltreatment')">
								<div class="icobox">
									<icon-svg class='iconclas' iconClass='shengchanzhizao'></icon-svg>
								</div>
								<p>报修</p>
							</div>
							<!--							<div class="icondetail_box" @click="$router.push('/Theautoparts')">-->
							<!--								<div class="icobox">-->
							<!--									<icon-svg class='iconclas' iconClass='wujingongju'></icon-svg>-->
							<!--								</div>-->
							<!--								<p>汽修</p>-->
							<!--							</div>-->
						</div>
						<div style="color: #666; text-align: center;margin-top: 3%;">点击对应图标 了解更多详情</div>
					</el-collapse-item>
				</el-collapse>
			</div>
		</div>
		<div class="Hotbox">
			<span id="tit">
				系统创新应用 <span style="color: #fd3f31;">HOT</span>
			</span>
			<div class="Hotbox_main bgc1" style="margin-top: 10%;">
				<div class="Hotbox_main_box">
					<p>大总管 <i style="font-size: 14px;">•</i> 律所办公</p>
					<button type="button" @click="$router.push('/LawFirm/LawFirm')">一键开启标准化</button>
				</div>
			</div>
			<div class="Hotbox_main bgc2">
				<div class="Hotbox_main_box">
					<p>大总管 <i style="font-size: 14px;">•</i> 智能报修</p>
					<button type="button" @click="$router.push('/BX')">扫码快速报修</button>
				</div>
			</div>
			<div class="Hotbox_main bgc3">
				<div class="Hotbox_main_box">
					<p>大总管 <i style="font-size: 14px;">•</i> OA办公</p>
					<button type="button" @click="$router.push('/phOA')">实时移动办公</button>
				</div>
			</div>
			<div class="Hotbox_main bgc4">
				<div class="Hotbox_main_box">
					<p>大总管 <i style="font-size: 14px;">•</i> 掌上管家</p>
					<button type="button" @click="$router.push('/gj')">随时随地看店</button>
				</div>
			</div>
		</div>
		<div class="infobox">
			<div class="infobox_main">
				<div class="infobox_main_flex">
					<div class="infobox_main_flex_box">
						<p>3年+</p>
						<span>
							专注软件研发
						</span>
					</div>
					<!-- <div class="infobox_main_flex_box">
						<p>10W+</p>
						<span>
							注册用户
						</span>
					</div> -->
					<div class="infobox_main_flex_box">
						<p>30+</p>
						<span>
							城市覆盖
						</span>
					</div>
					<div class="infobox_main_flex_box">
						<p>7X12h</p>
						<span>
							1V1售后团队
						</span>
					</div>
				</div>
				<div class="infobox_main_btn">
					<span>
						注册即可免费试用
					</span>
					<button type="button" @click="zcfun">立即注册</button>
				</div>
			</div>
		</div>
		<el-dialog :visible.sync="centerDialogVisible" center title="微信店铺" width="30%">
			<div id="ercodeimg">
				<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/wx.jpg">
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="centerDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
			</span>
		</el-dialog>
		<footers></footers>
		<div class="phone_btn">
			<el-button icon="el-icon-phone-outline" type="button" @click='phoneclickfun'>点我详询</el-button>
		</div>
	</div>
</template>

<script>
import footers from './FOOTER.vue'
export default {
	components: {
		footers
	},
	data() {
		return {
			imglist: [{
				url: 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/banner1.jpg'
			},
				{
					url: 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/banner2.jpg'
				},
				{
					url: 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/banner3.jpg'
				},
				{
					url: 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/banner4.jpg'
				},
        {
          url: 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/banner5.jpg'
        }
			],
			activeName: '5',
			centerDialogVisible: false
		};
	},
	mounted() {
		let bannerimg = document.getElementsByClassName('img')
		if (window.innerWidth < 720) {
			for (let i = 0; i < bannerimg.length; i++) {
				bannerimg[i].style.height = '200px'
			}
			document.getElementById('banner').style.height = '200px'
		} else {
			document.getElementById('banner').style.height = '600px'
			for (let i = 0; i < bannerimg.length; i++) {
				bannerimg[i].style.height = '600px'
			}
		}
		window.addEventListener('scroll', this.handleScrollx, true)
	},
	methods: {
		phoneclickfun() {
			window.location.href = 'tel:4006182019'
		},
		zcfun() {
			// window.location.href = "http://rhdgjzc.ronghengzn.com/";
		},
		OAfun(i) {
			this.$router.push('/OA')
			this.$store.commit('OAidfun', i)
		},
		handleScrollx() {
			if (window.innerWidth > 720) {
				console.log('滚动高度', window.pageYOffset)
				if (window.pageYOffset > 399) {
					$('#Leftone').animate({
						marginLeft: '30%'
					}, {
						duration: 1000,
						complete: function () {
							$('#Leftone').stop()
						}
					});
					$('#rightone').animate({
						marginLeft: '0'
					}, {
						duration: 1000,
						complete: function () {
							$('#Leftone').stop()
						}
					});
				}
				if (window.pageYOffset > 800) {
					$('#Lefttwo').animate({
						marginLeft: '30%'
					}, {
						duration: 1000,
						complete: function () {
							$('#Leftone').stop()
						}
					});
					$('#righttwo').animate({
						marginLeft: '0'
					}, {
						duration: 1000,
						complete: function () {
							$('#Leftone').stop()
						}
					});
				}
				if (window.pageYOffset > 1200) {
					$('#leftthree').animate({
						marginLeft: '30%'
					}, {
						duration: 1000,
						complete: function () {
							$('#Leftone').stop()
						}
					});
					$('#rightthree').animate({
						marginLeft: '0'
					}, {
						duration: 1000,
						complete: function () {
							$('#Leftone').stop()
						}
					});
				}
				if (window.pageYOffset > 1500) {
					$('#leftfour').animate({
						marginLeft: '30%'
					}, {
						duration: 1000,
						complete: function () {
							$('#Leftone').stop()
						}
					});
					$('#rightfour').animate({
						marginLeft: '0'
					}, {
						duration: 1000,
						complete: function () {
							$('#Leftone').stop()
						}
					});
				}
			}
		},
	},
  beforeDestory(){
    console.log('我是组件卸载')
    window.removeEventListener('scroll', this.handleScrollx, true)

  }
}
</script>

<style lang="less" scoped>
.img {
	height: 600px;
	width: 100%;
}

@media screen and (min-width: 720px) {
	/deep/ .el-carousel__container {
		height: 100%;
	}
	
	#ercodeimg {
		text-align: center;
	}
}

@media screen and (min-width: 720px) {
	.briefintroductionbox {
		width: 100%;
		height: 310px;
		background-color: #F9F9F9;
		margin-top: 2%;
	}
}

@media screen and (max-width: 720px) {
	.briefintroductionbox {
		display: none;
	}
}

.briefintroductionbox-main {
	width: 70%;
	height: 100%;
	margin-left: 15%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	
	.briefintroductionbox-main_flex_box {
		width: 30%;
		height: 90%;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		transition: all .8s;
		
		img {
			// width: 100%;
			height: 58%;
		}
		
		span {
			display: inline-block;
			font-size: 14px;
			height: 30%;
		}
	}
	
	.briefintroductionbox-main_flex_box:hover {
		width: 32%;
		height: 100%;
		// transform:translateX(10px)
	}
	
}

@media screen and (min-width: 720px) {
	.timershaftbix {
		width: 100%;
		height: 1500px;
		margin-top: 2%;
	}
}

@media screen and (max-width: 720px) {
	.timershaftbix {
		display: none;
	}
}

.timershaftbix_main {
	width: 90%;
	height: 100%;
	margin-left: 5%;
	background-color: #FFFFFF;
	padding-top: 2%;
	position: relative;
	
	h1 {
		text-align: center;
		margin-bottom: 5%;
	}
	
	.timershaftbix_main_flex_box {
		height: 90%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		
		.left {
			width: 45%;
			height: 100%;
			transition: all 1s;
			
			.timershaftbix_main_flex_box_box {
				width: 70%;
				margin-left: 30%;
				text-align: right;
				height: 25%;
				// padding-top: 2%;
				// height: 24%;
				// // background-color: #0095DD;
				
			}
			
			#Leftone {
				margin-left: -100%;
			}
			
			#Lefttwo {
				margin-left: -100%;
			}
			
			#leftthree {
				margin-left: -100%;
			}
			
			#leftfour {
				margin-left: -100%;
			}
			
		}
		
		.center {
			width: 10%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		
		.right {
			width: 45%;
			height: 100%;
			
			.timershaftbix_main_flex_box_box {
				width: 70%;
				height: 25%;
				// padding-top: 2%;
				// height: 24%;
				// // background-color: #0095DD;
				
			}
			
			#rightone {
				margin-left: 120%;
			}
			
			#righttwo {
				margin-left: 120%;
			}
			
			#rightthree {
				margin-left: 120%;
			}
			
			#rightfour {
				margin-left: 120%;
			}
		}
	}
	
	.timeimg {
		height: 98%;
		// position: absolute;
		// top: 18%;
		// margin-left: 44%;
	}
}

@media screen and (min-width: 720px) {
	.industrybox {
		width: 100%;
		height: 400px;
		margin-top: 3%;
		padding-top: 2%;
		background-color: #f0f0f0;
		
		h1 {
			text-align: center;
		}
		
		.industrybox_flex {
			margin-top: 3%;
			width: 76%;
			height: 70%;
			margin-left: 12%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			
			.industrybox_flex_box {
				width: 24%;
				height: 90%;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-wrap: wrap;
				transition: all .8s;
				
				// align-content: center;
				.btns {
					width: 200px;
					height: 50px;
					background-color: rgba(0, 0, 0, 0);
					border: 1px solid #fd3f31;
					color: #fd3f31;
					border-radius: 5px;
				}
				
				.iconclas {
					color: #fd3f31;
					display: block;
					width: 100%;
					font-size: 70px;
				}
			}
			
			.industrybox_flex_box:hover {
				background-color: #FFFFFF;
				border-radius: 5px;
				width: 25%;
				height: 100%;
				
			}
		}
	}
}

@media screen and (max-width: 720px) {
	.industrybox {
		display: none;
	}
}

@media screen and (min-width: 720px) {
	.manualbox {
		width: 100%;
		height: 400px;
		margin-top: 2%;
		text-align: center;
		padding-top: 1%;
		
		h1 {
			text-align: center;
			margin-bottom: 1%;
		}
		
		span {
			font-size: 20px;
			color: #7f7f7f;
		}
		
		.cardboxs {
			width: 76%;
			margin-top: 2%;
			height: 70%;
			margin-left: 12%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			
			.card_flex_box {
				width: 19%;
				height: 95%;
				background-color: #FFFFFF;
				box-shadow: 1px 1px 5px #d2d2d2;
				cursor: pointer;
				transition: all .6s;
				
				.card_flex_box_header {
					height: 20%;
					line-height: 56px;
					font-size: 18px;
					font-weight: bold;
					color: #fd3f31;
				}
				
				.card_flex_box_img {
					max-width: 100%;
					height: 80%;
				}
			}
			
			.card_flex_box:hover {
				width: 20%;
				height: 100%;
				box-shadow: 1px 1px 5px #a1a1a1;
			}
		}
	}
}

@media screen and (max-width: 720px) {
	.manualbox {
		display: none;
	}
}

@media screen and (min-width: 720px) {
	.bottombox {
		width: 100%;
		height: 800px;
		background-image: url(https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/bgc.jpg);
		background-size: 100% 100%;
		margin-top: 3%;
		
		.bottombox_main {
			width: 100%;
			height: 100%;
			padding-top: 4%;
			background-color: rgba(255, 255, 255, 0.8);
			
			h1 {
				text-align: center;
			}
			
			.bottom_box_flex_box {
				width: 76%;
				height: 75%;
				margin-top: 2%;
				margin-left: 12%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				
				.bottom_box_flex_box_box {
					width: 32%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-wrap: wrap;
					align-content: center;
					
					img {
						height: 50%;
						max-width: 100%;
					}
					
					p {
						width: 100%;
						text-align: center;
					}
				}
			}
			
			#buttons {
				width: 30%;
				height: 60px;
				margin-left: 35%;
				background-color: #fd3f31;
				color: #fff;
				border: 1px solid #fd3f31;
				border-radius: 5px;
			}
		}
	}
}

@media screen and (max-width: 720px) {
	.bottombox {
		display: none;
	}
}

h1 {
	font-size: 40px !important;
}

//手机端介绍页样式
@media screen and (min-width: 720px) {
	.phone_bri {
		display: none;
	}
}

@media screen and (max-width: 720px) {
	.phone_bri {
		width: 100%;
		height: 400px;
		margin-top: 10%;
		text-align: center;
		
		h2 {
			text-align: center;
			font-size: 26px;
			color: #fd3f31;
		}
		
		p {
			color: #666;
		}
		
		img {
			max-width: 100%;
			height: 60%;
		}
		
		.text {
			width: 70%;
			margin-left: 15%;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			margin-bottom: 2%;
			
			p {
				width: 50%;
				margin: 0;
				
				.icl {
					color: #fd3f31;
				}
			}
		}
		
		.bton {
			display: flex;
			justify-content: center;
			align-items: center;
			
			button {
				background-color: rgba(0, 0, 0, 0);
				border: 1px solid #fd3f31;
				color: #fd3f31;
				border-radius: 20px;
				width: 50%;
				height: 35px;
			}
		}
	}
}

// 手机端行业介绍
@media screen and (min-width: 720px) {
	.phone_industry {
		display: none;
	}
}

@media screen and (max-width: 720px) {
	.phone_industry {
		width: 100%;
		height: 100%;
		margin-top: 10%;
		padding-top: 3%;
		
		.title {
			text-align: center;
			font-weight: bold;
			font-size: 18px;
		}
		
		#collapse {
			width: 80%;
			margin-left: 10%;
			
			.iconclas {
				color: #fd3f31;
				font-size: 26px;
			}
			
			.detail_box {
				width: 80%;
				margin-left: 10%;
				border-left: 3px solid #fd3f31;
				padding-left: 5%;
				
				p {
					font-size: 17px;
					font-weight: bold;
				}
			}
			
			.btns {
				display: flex;
				justify-content: space-around;
				align-items: center;
				margin-top: 5%;
				
				button {
					width: 30%;
					height: 25px;
					background-color: #FFFFFF;
					border-radius: 20px;
					border: 1px solid #fd3f31;
					color: #666;
				}
			}
			
			.icon-font_box {
				width: 90%;
				margin-left: 5%;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				flex-wrap: wrap;
				
				.icondetail_box {
					width: 25%;
					text-align: center;
				}
			}
		}
	}
}

// 手机端行业介绍
@media screen and (min-width: 720px) {
	.Hotbox {
		display: none;
	}
}

// 手机端行业介绍
@media screen and (max-width: 720px) {
	.Hotbox {
		width: 100%;
		height: 700px;
		margin-top: 20%;
		text-align: center;
		
		#tit {
			font-size: 20px;
			font-weight: bold;
		}
		
		.Hotbox_main {
			width: 90%;
			margin-left: 5%;
			height: 20%;
			// background-color: pink;
			margin-top: 2%;
			
			.Hotbox_main_box {
				width: 100%;
				height: 100%;
				background-color: rgba(0, 0, 0, 0.6);
				display: flex;
				justify-content: center;
				align-items: center;
				flex-wrap: wrap;
				align-content: center;
				
				p {
					width: 100%;
					color: #FFFFFF;
					font-size: 22px;
				}
				
				button {
					color: #FFFFFF;
					background-color: #fd3f31;
					width: 40%;
					height: 30px;
					font-size: 14px;
					border-radius: 15px;
				}
			}
		}
		
		.bgc1 {
			background-image: url('https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/shopbgc.jpg');
			background-size: 100% 100%;
		}
		
		.bgc2 {
			background-image: url('https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/wxbgc.jpg');
			background-size: 100% 100%;
		}
		
		.bgc3 {
			background-image: url('https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/bgbgc.jpg');
			background-size: 100% 100%;
		}
		
		.bgc4 {
			background-image: url('https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/gjbgc.jpg');
			background-size: 100% 100%;
		}
	}
}

@media screen and (min-width: 720px) {
	.infobox {
		display: none;
	}
}

@media screen and (max-width: 720px) {
	.infobox {
		width: 100%;
		height: 400px;
		background-image: url('https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/xzlbgc.jpg');
		background-size: 100% 100%;
		
		.infobox_main {
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.8);
			
			.infobox_main_flex {
				width: 80%;
				margin-left: 10%;
				height: 60%;
				border-bottom: 1px solid #fd3f31;
				display: flex;
				flex-wrap: wrap;
				
				.infobox_main_flex_box {
					width: 50%;
					height: 50%;
					display: flex;
					justify-content: center;
					align-items: center;
					align-content: center;
					flex-wrap: wrap;
					text-align: center;
					
					p {
						width: 100%;
						font-size: 26px;
						color: #fd3f31;
						margin: 0;
					}
					
					span {
						color: #FFFFFF;
					}
				}
			}
			
			.infobox_main_btn {
				width: 80%;
				margin-left: 10%;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 10%;
				color: #FFFFFF;
				flex-wrap: wrap;
				
				span {
					display: block;
					width: 100%;
					text-align: center;
				}
				
				button {
					width: 80%;
					height: 50px;
					border-radius: 25px;
					background-color: rgba(0, 0, 0, 0);
					border: 1px solid #fd3f31;
					color: #fd3f31;
					margin-top: 8%;
				}
			}
		}
	}
}

@media screen and (min-width: 720px) {
	.phone_btn {
		display: none;
	}
}

@media screen and (max-width: 720px) {
	.phone_btn {
		position: absolute;
		bottom: 1%;
		position: sticky;
		position: -webkit-sticky; // 兼容 -webkit 内核的浏览器
		top: 0px;
		display: flex;
		justify-content: center;
		align-items: center;
		
		button {
			background-color: #fd3f31;
			color: #FFFFFF;
			width: 80%;
			border-radius: 25px;
			font-size: 18px;
			border: none;
		}
	}
}
</style>
